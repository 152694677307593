@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wdth,wght@80,400;100,400;100,600;100,900;200,400&display=swap);
@keyframes pulse {
    0% {
        opacity: 0.2;
    }
    100% {
        opacity: 0.4;
    }
}

#chart {
    width: 100%;
    align-self: flex-start;
    justify-self: center;
    max-width: 667px;
}

@media (min-width: 930px) {
    #chart {
        grid-column: span all;
        grid-row-start: 1;
        grid-column-start: 2;
        overflow-x: hidden;
    }
}

@media (min-width: 786px) and (max-width: 929px) {
    #chart {
        max-width: 450px;
        overflow-x: hidden;
    }
    .legend {
        display: none;
    }
}

@media (min-width: 681px) and (max-width: 785px) {
    #chart {
        max-width: 375px;
        overflow-x: hidden;
        grid-row-start: 2;
    }
    .legend {
        display: none;
    }
}

@media (max-width: 680px) {
    #chart {
        max-width: 315px;
        overflow-x: hidden;
        grid-row-start: 2;
    }
    .legend {
        display: none;
    }
}

#barChart {
    justify-self: left;
}

.legend {
    font-size: 12px;
    text-transform: uppercase;
}

[data-name="HTML/CSS"] {
    text-anchor: end;
}

[data-name="Javascript"] {
    text-anchor: middle;
}

[data-name="Product Design"] {
    text-anchor: start;
}

[data-name="Product Management"] {
    text-anchor: start;
}

[data-name="Graphic Design"] {
    text-anchor: end;
}

/* Refactor this to be a selected or current class */
[class='2022'] [data-series="2022"],
[class='2021'] [data-series="2021"],
[class='2020'] [data-series="2020"],
[class='2019'] [data-series="2019"],
[class='2018'] [data-series="2018"],
[class='2017'] [data-series="2017"],
[class='2016'] [data-series="2016"],
[class='2015'] [data-series="2015"],
[class='2014'] [data-series="2014"] {
    fill: #E95C20 !important;
    fill-opacity: 1 !important;
    animation: pulse 1s ease 0s infinite alternate-reverse;
}

[data-series*="20"]{
    cursor: pointer;
} 

.bar {
    fill: #E95C20;
    fill-opacity: .32;
}

[class='y axis'] {
    font-size: 12px;
    font-family: 'Inconsolata';
    font-weight: 600;
    text-transform: uppercase;
    transform: translate(9px, -3px);
}

.axis path,
.axis line {
    fill: none;
    display: none;
}
.App {
  font-family: 'Inconsolata','Courier New', Courier, monospace;
  font-size: 14px;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-template-rows: repeat(auto-fill, 116px);
  grid-gap: 0.5rem 1.85rem;
  justify-items: center;
  justify-content: center;
  padding: 32px 48px;
}

@media (min-width: 930px) {
  .leftContainer, .rightContainer {
      grid-column: span 1;
      grid-column-start: 1;
  }
}

@media (min-width: 786px) and (max-width: 929px) {
  .App {
    grid-template-rows: repeat(auto-fill, 64px);
  }
  .leftContainer {
      grid-column: span 3;
      justify-self: left;
  }
}

@media (max-width: 785px) {
  .App {
    grid-template-rows: repeat(auto-fill,minmax(64px, auto));
    grid-template-columns: 1fr;
  }
  .leftContainer {
      justify-self: left;
  }
}

.contactLinks {
  padding-bottom: 1.25rem;
  font-weight: 600;
}

.leftContainer div:last-of-type {
  font-size: 20px;
}

.years {
  padding: 1.25rem 0;
  font-weight: 600;
}

.contactLinks a {
  padding-right: 0.5rem;
  cursor: pointer;
}

.years div {
  display: inline-block;
  margin-right: 0.5rem;
  cursor: pointer;
}

.years div:hover {
  background-color: rgba(0,0,0,0.08);
}

/* Refactor this to be a selected or current class */
[class="years 2022"] [data-year="2022"],
[class="years 2021"] [data-year="2021"],
[class="years 2020"] [data-year="2020"],
[class="years 2019"] [data-year="2019"],
[class="years 2018"] [data-year="2018"],
[class="years 2017"] [data-year="2017"],
[class="years 2016"] [data-year="2016"],
[class="years 2015"] [data-year="2015"],
[class="years 2014"] [data-year="2014"] {
  background-color:rgba(233, 93, 32, 0.32);
}

.description div:first-of-type {
  font-stretch: ultra-expanded;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}

.description div:nth-of-type(2) {
  font-weight: 600;
  padding-bottom: 0.5rem;
}


body {
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
}
